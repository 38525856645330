import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FormCheck, Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import validator from "validator";
import { Col, Row } from "react-bootstrap";
import { EyeSlash, Eye } from "react-bootstrap-icons";
import Input from "./Input";
import { getBlob, post } from "../utils/api";
import Button from "./Button";

// TODO - use modal component everywhere instead of importing modal from react-bootstrap multiple times!!!
const ChangePasswordModal = ({ show, setShow, trialSubscription }) => {
  const navigate = useNavigate();
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [checked, setChecked] = useState(false);

  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handlePasswordChange = async (event) => {
    setPassword(event.target.value);
  };
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleChangePasswordFormSubmit = async (event) => {
    event.preventDefault();

    if (!validator.isStrongPassword(password, { minLength: 12 })) {
      setPasswordError(
        "Password should be at least 12 char, contain at least one uppercase, one lowercase, one number and one special character"
      );
      return;
    } else {
      setPasswordError("");
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      return;
    } else {
      setConfirmPasswordError("");
    }

    const currentDateTime = new Date();
    const formattedDate = `${currentDateTime.getFullYear()}-${String(currentDateTime.getMonth() + 1).padStart(2, '0')}-${String(currentDateTime.getDate()).padStart(2, '0')} ${String(currentDateTime.getHours()).padStart(2, '0')}:${String(currentDateTime.getMinutes()).padStart(2, '0')}:${String(currentDateTime.getSeconds()).padStart(2, '0')}.${String(currentDateTime.getMilliseconds()).padStart(3, '0')}`;

    const body = {
      password: password,
      confirmPassword: confirmPassword,
      trialSubscriptionAgreedDate: checked ? formattedDate : null,
    };
    setIsChangingPassword(true);
    try {
      const response = await post("user/password", body);

      if (response.status === 200) {
        toast.success("Password has been changed successfully");
        setPassword("");
        setConfirmPassword("");
        setShow(false);
        navigate("/new");
      }
    } catch (error) {
      toast.error(error.response.data.name);
    } finally {
      setIsChangingPassword(false);
    }
  };

  const handleCheckbox = () => {
    setChecked((prev) => !prev);
  };

  const handleOpenAgreement = async (event) => {
    event.preventDefault();
    
    try {
      const fileName = 'Pete Limited Trial Agreement.pdf';
      const url = `privacy-notice?document=${encodeURIComponent(fileName)}`;
      const response = await getBlob(url);
      const pdfUrl = window.URL.createObjectURL(response);
      window.open(pdfUrl, '_blank');
    } catch (error) {
      toast.error(error.response.data.name);
    }
  };

  const handleBackClick = () => {
    setShow(false);
    setPassword("");
    setConfirmPassword("");
  };
  return (
    <>
      <Modal show={show} backdrop="static">
        <Modal.Header>
          <h3 className="display-4">Change Password</h3>
        </Modal.Header>
        <form onSubmit={handleChangePasswordFormSubmit}>
          <Modal.Body>
            <Row className="align-items-center">
              <Col>
                <Input
                  id="inputPassword"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  className="form-control px-4 custom-border"
                  name="password"
                  value={password}
                  label="Password"
                  onChange={handlePasswordChange}
                  error={passwordError}
                  showPasswordToggle={true}
                />
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col>
                <Input
                  id="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  className="form-control px-4 custom-border"
                  name="password"
                  value={confirmPassword}
                  label="Confirm Password"
                  onChange={handleConfirmPasswordChange}
                  error={confirmPasswordError}
                  showPasswordToggle={true}
                />
              </Col>
            </Row>
            {trialSubscription && (
                <>
                  <Row className="align-items-center">
                    <Col>
                      <label
                        htmlFor="inputCompany"
                        className="custom-control-label text-left mb-1 fs-6 custom-text"
                      >
                        <strong>Welcome!</strong> We're pleased to offer you a free trial of Pete. Before you begin, please review and accept our{" "}
                        <Link to="#" onClick={handleOpenAgreement}>Free Limited Trial Agreement</Link>.  
                        By selecting <strong>"I Agree"</strong>, you acknowledge that you have read, understood, and accepted the terms and conditions of this free trial.
                      </label>
                    </Col>
                  </Row>

                  <Row className="align-items-center">
                    <Col>
                      <FormCheck
                        id="create-default-page"
                        type="checkbox"
                        label="I Agree to the Free Limited Trial Agreement."
                        checked={checked}
                        onChange={handleCheckbox}
                      />
                    </Col>
                  </Row>
                </>
              )}
          </Modal.Body>
          <Modal.Footer className="padding-bottom-20 d-flex justify-content-end gap-2">
          <Button
              type="submit"
              className="px-8"
              disabled={isChangingPassword || (trialSubscription && !checked)}
            >
              Change Password
            </Button>
            {trialSubscription && (
              <Button
                type="button" 
                variant="secondary"
                className="px-8"
                onClick={handleBackClick}
              >
                Back
              </Button>
            )}
          </Modal.Footer>
        </form>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default ChangePasswordModal;
